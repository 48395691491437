.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #045bc1;
}
.nav-pills .nav-link {
  border-radius: 0.1875rem;
}
.nav-link {
  padding: 0.5rem 1rem;
  color: #045bc1;
}

.qr-code-container {
  max-width: 100%;
}

.qr-code {
  max-width: 500px;
  max-height: 500px;
  position: sticky;
  z-index: 1050;
  top: 10px;
}

.qr-code canvas {
  max-width: 300px !important;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
}

.offcanvas-start {
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out !important;
  width: 25vw;
}

.btn-success {
  background-color: #42ba96;
  border-color: #42ba96;
  box-shadow: none;
  color: #fff;
}

.btn-primary,
.btn-outline-primary {
  color: #045bc1;
  border-color: #045bc1;
}

.btn-primary,
.btn-outline-primary:hover {
  color: #fff;
  background-color: #045bc1;
  border-color: #045bc1;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

hr {
  background-color: #a2a2a2;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-switch .form-check-input {
  width: 40px;
  height: 20px;
  margin-right: 1rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.65;
}